import { DEX_GURU_WRAPPED_TOKEN_ADDRESS } from '../config/tokens'
import { Address, TokenStaticV2, TokenStaticV3, TokenV3 } from '../model'

export const mapToDgWrappedTokenStatic = (
  token: TokenStaticV2 | TokenStaticV3
): TokenStaticV2 | TokenStaticV3 => {
  const network = token.id.split('-')[1]
  return {
    ...token,
    address: DEX_GURU_WRAPPED_TOKEN_ADDRESS,
    id: `${DEX_GURU_WRAPPED_TOKEN_ADDRESS}-${network}`,
  }
}

export const mapToDgWrappedToken = (token: TokenV3): TokenV3 => {
  return {
    ...token,
    address: DEX_GURU_WRAPPED_TOKEN_ADDRESS,
    id: `${DEX_GURU_WRAPPED_TOKEN_ADDRESS}-${token.network}`,
  }
}

export const isDgNativeTokenAddress = (address: Address): boolean => {
  return address.toLowerCase() === DEX_GURU_WRAPPED_TOKEN_ADDRESS.toLocaleLowerCase()
}
