import BigNumber from 'bignumber.js'

interface FinancialFormatOptions {
  decimals?: number
  prefix?: string
  suffix?: string
  roundBigNumbersFrom?: 'K' | 'M' | 'B' | 'T'
  highPrecession?: boolean
}

type RoundingMode = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

const _prepareValue = (value: string | number | BigNumber): BigNumber | false => {
  if (!value && value !== 0) {
    return false
  }

  const data =
    value instanceof BigNumber
      ? value
      : new BigNumber(typeof value === 'number' ? value.toFixed(18) : value)

  return data
}

const _appendPrefixSuffix = (data: string, prefix = '', suffix = ''): string =>
  `${prefix}${data}${suffix}`

/*
  Rounds the number according 
  https://app.clubhouse.io/dexguru/story/6331/rounding-round-3
  0.0000001 => 7 digits after . (dot) if less than 1
  from 1.0001 to 100 => 2 digits after . (dot)
  > 100 => no digits after . (dot) 100.12 => 100
  also inserts , separator in thousand (e.g. 1,234,2324.0156)
  roundBigNumbersFrom rounds a letter for K - thousands, M - millions, B - billions
  highPrecession option renders 4 (for >1) or 18 (for <1) digits after . (dot) and doesn't support letter rounding
  decimals parameter overrides the above ones if passed
  prefix adds a specified string before the price
  @returns {string} Formatted string to display in components
*/
export const financialFormat = (
  value: string | number | BigNumber,
  options?: FinancialFormatOptions
): string => {
  const roundBigNumbersFrom = options?.roundBigNumbersFrom || 'B' // by default round M and B

  let decimals = options?.decimals

  let roundingDirections: RoundingMode = BigNumber.ROUND_HALF_UP

  const formatConfig = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: '',
  }

  // if (!price) is true for 0, so need to compare with undefined explicitly
  let data = _prepareValue(value)

  if (data === false) {
    return ''
  }

  const isNegative = data.lt(0)
  data = data.abs()

  if (decimals === undefined) {
    if (data.lt(1) && data.gt(-1)) {
      decimals = 7
    }
    if ((data.gte(1) && data.lt(100)) || (data.lte(-1) && data.gt(-100))) {
      decimals = 2
    }
    if (data.gte(100) || data.lte(-100)) {
      decimals = 0
    }
  }

  if (options?.highPrecession) {
    roundingDirections = BigNumber.ROUND_DOWN

    if (options?.decimals === 0) {
      decimals = 0
    } else {
      if (data.lt(1) && data.gt(-1)) {
        decimals = options?.decimals || 18
      }
      if (data.gte(1) || data.lte(-1)) {
        decimals = options?.decimals || 4
      }
    }
  }

  if (roundBigNumbersFrom && !options?.highPrecession) {
    if (data.gte(1000) && data.lt(1000000) && roundBigNumbersFrom === 'K') {
      formatConfig.suffix = 'K'
      data = data.shiftedBy(-3)
      decimals = 1
    }
    if (
      data.gte(1000000) &&
      data.lt(1000000000) &&
      (roundBigNumbersFrom === 'M' || roundBigNumbersFrom === 'K')
    ) {
      formatConfig.suffix = 'M'
      data = data.shiftedBy(-6)
      formatConfig.decimalSeparator = '.'
      decimals = 2
    }
    if (
      data.gte(1000000000) &&
      data.lt(1000000000000) &&
      (roundBigNumbersFrom === 'B' || roundBigNumbersFrom === 'M' || roundBigNumbersFrom === 'K')
    ) {
      formatConfig.suffix = 'B'
      data = data.shiftedBy(-9)
      formatConfig.decimalSeparator = '.'
      decimals = 2
    }
    if (data.gte(1000000000000)) {
      formatConfig.suffix = 'T'
      data = data.shiftedBy(-12)
      formatConfig.decimalSeparator = '.'
      decimals = 2
    }
  }

  if (isNegative) {
    data = data.multipliedBy(-1)
  }
  const result = data.toFormat(decimals || 0, roundingDirections, formatConfig)

  const clean = result.match(/^0*([0-9,]+(?:\.?(?:(?!0+$)[0-9MBKT])+)?)/) // remove trailing zeroes

  if (clean) {
    return _appendPrefixSuffix(clean[1], options?.prefix, options?.suffix)
  } else {
    return _appendPrefixSuffix(result, options?.prefix, options?.suffix)
  }
}

// Low Value (<1) High Precision shortcut
export const financialFormatLowValueHighPrecision = (
  value: string | number | BigNumber,
  options?: FinancialFormatOptions
): string => {
  let data = _prepareValue(value)

  if (data === false) {
    return ''
  }

  const isNegative = data.lt(0)
  data = data.abs()

  const optionsDiff = data.lt(1) ? { highPrecession: true } : {}

  if (isNegative) {
    data = data.multipliedBy(-1)
  }

  const newOptions = { ...options, ...optionsDiff }

  return financialFormat(data, newOptions)
}

export const decorator = (value: string): string => {
  if (!value) {
    return ''
  }

  let _value = value.replace(/[,]/g, '')
  _value = digitsOnly(_value)

  if (!_value) {
    return ''
  }

  const splitted = _value.split('.')
  const valueCeil = financialFormat(splitted[0], { decimals: 0, highPrecession: true })

  return splitted.length === 2 ? `${valueCeil}.${splitted[1]}` : `${valueCeil}`
}

export const finalDecorator = (value?: string | number): string => {
  if (!value) {
    return ''
  }

  const stringValue = value.toString()

  if (/\.[0]+$/g.test(stringValue)) {
    return stringValue
  }

  let decoratedValue: string = decorator(stringValue)

  if (!decoratedValue) {
    return ''
  }

  const splitted = decoratedValue.split('.')

  let tailValue = ''
  if (splitted[1]) {
    tailValue = splitted[1] || ''
    const tailNull = /(0)*$/.exec(splitted[1])

    if (tailNull && tailNull[0]) {
      tailValue = tailValue.slice(0, tailNull?.index)
    }
  }

  if (splitted[0] && tailValue) {
    decoratedValue = `${splitted[0]}.${tailValue}`
  }

  if (splitted[0] && !tailValue) {
    decoratedValue = `${splitted[0]}`
  }

  return decoratedValue
}

export const digitsOnly = (value: string): string => {
  const _value = value.replace(/\s/g, '')

  if (!_value || Number.isNaN(Number(_value))) {
    return ''
  }

  return value
}

export const unformat = (value: string): string => {
  return value.replace(/,/g, '')
}

export const safeToNumber = (value?: string): number | undefined => {
  if (value === undefined) {
    return undefined
  }

  return Number(unformat(value))
}
