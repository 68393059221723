import BigNumber from 'bignumber.js'

import { TotalTradesAmount, TradeBarData, TransactionModel } from '../model'

export const getTotalTradesInTable = (data: TransactionModel[]): TotalTradesAmount => {
  return data?.reduce<TotalTradesAmount>(
    (acc: TotalTradesAmount, e: TransactionModel) => {
      return {
        usd: acc.usd.plus(e.amountStable || new BigNumber(0)),
        eth: acc.eth.plus(e.amountNative || new BigNumber(0)),
      }
    },
    { usd: new BigNumber(0), eth: new BigNumber(0) }
  )
}

export const getTradeBarsData = (data: TransactionModel[]): TradeBarData => {
  return data?.reduce(
    (acc: TradeBarData, e: TransactionModel) => {
      return {
        total: {
          usd: acc.total.usd.plus(e.amountStable || new BigNumber(0)),
          eth: acc.total.eth.plus(e.amountNative || new BigNumber(0)),
        },
        max: {
          usd: Math.max(acc.max.usd, Number(e.amountStable)),
          eth: Math.max(acc.max.eth, Number(e.amountNative)),
        },
        min: {
          usd: Math.min(acc.min.usd, Number(e.amountStable)),
          eth: Math.min(acc.min.eth, Number(e.amountNative)),
        },
      }
    },
    {
      max: { usd: 0, eth: 0 },
      min: { usd: Number.MAX_SAFE_INTEGER, eth: Number.MAX_SAFE_INTEGER },
      total: { usd: new BigNumber(0), eth: new BigNumber(0) },
    } as TradeBarData
  )
}
