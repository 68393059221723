import { BigNumber } from 'bignumber.js'

import { GasType } from '../config/verifyConstants'
import {
  GasFeeType,
  GasPrice,
  GasPriceEIP1559,
  ZeroXGasApiResponseItem,
  ZeroXWrapperGasApiResponseItemEIP1559,
} from '../model'

const calculateGasPricesOld = (gasPriceData: ZeroXGasApiResponseItem): GasPrice => {
  return {
    fast: new BigNumber(gasPriceData.fast),
    instant: new BigNumber(gasPriceData.instant),
    overkill: new BigNumber(gasPriceData.instant).times(1.3),
  }
}
const calculateGasPricesEIP1559 = (
  gasPriceData: ZeroXWrapperGasApiResponseItemEIP1559
): GasPriceEIP1559 => {
  return {
    fast: {
      baseFee: new BigNumber(gasPriceData['eip-1559'].fast.baseFee),
      maxFee: new BigNumber(gasPriceData['eip-1559'].fast.maxFee),
      maxPriorityFee: new BigNumber(gasPriceData['eip-1559'].fast.maxPriorityFee),
    },
    instant: {
      baseFee: new BigNumber(gasPriceData['eip-1559'].instant.baseFee),
      maxFee: new BigNumber(gasPriceData['eip-1559'].instant.maxFee),
      maxPriorityFee: new BigNumber(gasPriceData['eip-1559'].instant.maxPriorityFee),
    },
    overkill: {
      baseFee: new BigNumber(gasPriceData['eip-1559'].overkill.baseFee),
      maxFee: new BigNumber(gasPriceData['eip-1559'].overkill.maxFee),
      maxPriorityFee: new BigNumber(gasPriceData['eip-1559'].overkill.maxPriorityFee),
    },
  }
}

export const calculateGasPrices = (
  gasPriceData: ZeroXGasApiResponseItem | ZeroXWrapperGasApiResponseItemEIP1559
): GasPrice | GasPriceEIP1559 => {
  // when gasPriceData: ZeroXGasApiResponseItem
  if (
    Object.prototype.hasOwnProperty.call(gasPriceData, 'fast') &&
    Object.prototype.hasOwnProperty.call(gasPriceData, 'instant')
  ) {
    return calculateGasPricesOld(gasPriceData as ZeroXGasApiResponseItem)
  } else {
    // gasPriceData: ZeroXWrapperGasApiResponseItemEIP1559
    return calculateGasPricesEIP1559(gasPriceData as ZeroXWrapperGasApiResponseItemEIP1559)
  }
}

const getGasValuesForShowOld = (gasPrice: GasPrice): GasPrice => {
  const fast = gasPrice.fast.div(10 ** 9)
  const instant = gasPrice.instant.div(10 ** 9)
  const overkill = gasPrice.overkill.div(10 ** 9)

  return { fast, instant, overkill }
}

const getGasValuesForShowEIP1559 = (gasPrice: GasPriceEIP1559): GasPrice => {
  const fast = (gasPrice as GasPriceEIP1559).fast.maxPriorityFee.div(10 ** 9)
  const instant = (gasPrice as GasPriceEIP1559).instant.maxPriorityFee.div(10 ** 9)
  const overkill = (gasPrice as GasPriceEIP1559).overkill.maxPriorityFee.div(10 ** 9)

  return { fast, instant, overkill }
}

export const getGasValuesForShow = (gasPrice: GasPrice | GasPriceEIP1559): GasPrice => {
  // when gasPrice: GasPrice
  if (
    gasPrice.fast instanceof BigNumber &&
    gasPrice.instant instanceof BigNumber &&
    gasPrice.overkill instanceof BigNumber
  ) {
    return getGasValuesForShowOld(gasPrice as GasPrice)
  } else {
    // gasPrice: GasPriceEIP1559
    return getGasValuesForShowEIP1559(gasPrice as GasPriceEIP1559)
  }
}

export const getGasPriceByKey = (
  gasPrice: GasPrice | GasPriceEIP1559,
  key: GasFeeType
): BigNumber => {
  let selected: BigNumber
  // when gasPrice: GasPrice
  if (
    gasPrice.fast instanceof BigNumber &&
    gasPrice.instant instanceof BigNumber &&
    gasPrice.overkill instanceof BigNumber
  ) {
    selected = (gasPrice as GasPrice)[key]
    if (key === GasType.overkill && gasPrice.instant instanceof BigNumber) {
      selected = new BigNumber(gasPrice.instant).times(1.3).isInteger()
        ? new BigNumber(gasPrice.instant).times(1.3)
        : new BigNumber(gasPrice.instant).times(1.3).dp(0, BigNumber.ROUND_DOWN)
    }
  } else {
    // gasPrice: GasPriceEIP1559
    selected = (gasPrice as GasPriceEIP1559)[key].maxFee
  }

  return selected
}
