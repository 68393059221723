import { model } from '@telekomconsalting/dex-guru-model'
import web3Utils from 'web3-utils'

import {
  Operators,
  OrderFilterTypes,
  TokenStatuses,
  TradeSizeUsdFilter,
  TransactionTypes,
} from '../model'

type ValidationResult = boolean
type Validator = (value: unknown) => ValidationResult

interface ValidatorFnOptions {
  shortNetworksNames: Array<string>
}

type ValidatorWithOptions = (value: unknown, options?: ValidatorFnOptions) => ValidationResult

export const isTransactionTypesValid: Validator = (value) => {
  if (!Array.isArray(value)) {
    return false
  }

  for (const filter of value) {
    if (!Object.keys(TransactionTypes).includes(filter)) {
      return false
    }
  }

  return true
}

export const isValidAmm: Validator = (value) => typeof value === 'string'

export const isValidTxType: Validator = (value) =>
  typeof value === 'string' && Object.values(TransactionTypes).includes(value as TransactionTypes)

export const isValidTokenStatus: Validator = (value) =>
  typeof value === 'string' && Object.values(TokenStatuses).includes(value as TokenStatuses)

export const isValidAddress: Validator = (value) =>
  typeof value === 'string' && web3Utils.isAddress(value)

export const isValidDate: Validator = (value) =>
  !!value &&
  Number.isInteger((value as { start_date?: number; end_date?: number }).start_date) &&
  Number.isInteger((value as { start_date?: number; end_date?: number }).end_date)

export const isValidNetwork: ValidatorWithOptions = (value, options) => {
  if (typeof value !== 'string' || Array.isArray(options?.shortNetworksNames)) {
    return false
  }
  if (Array.isArray(options?.shortNetworksNames)) {
    return (options?.shortNetworksNames as unknown as Array<string>).includes(value as string)
  } else {
    // when options aren't passed, check just for string
    return true
  }
}

export const isValidTokenId: Validator = (
  value,
  options?: { shortNetworksNames: Array<string> }
) => {
  if (typeof value !== 'string') {
    return false
  }

  const [address, network] = value.split('-')

  return isValidAddress(address) && isValidNetwork(network, options)
}

export const isValidOrder: Validator = (value) =>
  typeof value === 'string' && !Object.keys(OrderFilterTypes).includes(value)

export const isValidSortBy: Validator = (value) => typeof value === 'string'

export const isValidCategory: Validator = (value) => {
  if (!Array.isArray(value) || !value.length) {
    return false
  }

  for (const filter of value) {
    if (!Object.values(model.TraderCategoryName).includes(filter)) {
      return false
    }
  }

  return true
}

export const isValidLiquidityProviderCategory: Validator = (value) => {
  if (!Array.isArray(value) || !value.length) {
    return false
  }
  for (const filter of value) {
    if (!Object.values(model.LiquidityProviderCategoryName).includes(filter)) {
      return false
    }
  }

  return true
}

export const isValidExchangeTokenTrade: Validator = (value) => !!value

export const isValidOperator: Validator = (value) =>
  Object.values(Operators).includes(value as Operators)

export const isNumeric: Validator = (str) => {
  if (typeof str != 'string') {
    return false
  } // we only process strings!
  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  )
}

export const isValidTradeSize: Validator = (value) =>
  typeof value === 'object' &&
  isValidOperator((value as TradeSizeUsdFilter)?.operator) &&
  typeof (value as TradeSizeUsdFilter)?.amount === 'number'

export const isValidTradeSizeStr: Validator = (value) =>
  typeof value === 'object' &&
  isValidOperator((value as TradeSizeUsdFilter)?.operator) &&
  isNumeric((value as TradeSizeUsdFilter)?.amount)

export const isValidWalletCategory: Validator = (value) => {
  if (!Array.isArray(value) || !value.length) {
    return false
  }
  const walletCategory = { ...model.TraderCategoryName, ...model.LiquidityProviderCategoryName }
  for (const filter of value) {
    if (!Object.values(walletCategory).includes(filter)) {
      return false
    }
  }

  return true
}

export const isValidTxTypeMintBurnTransfer: Validator = (value) => {
  if (!Array.isArray(value) || !value.length) {
    return false
  }

  for (const filter of value) {
    if ([TransactionTypes.swap].includes(filter)) {
      return false
    }
  }

  return true
}
