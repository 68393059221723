import { getNetworkConfig } from '../utils'

const switchNetwork = async (network: string): Promise<void> => {
  const provider = window.ethereum
  const networkConfig = getNetworkConfig(network)
  const chainId = networkConfig.id
  const symbol = networkConfig.native_token.symbols[0]

  if (provider && provider.request) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${parseInt(`${chainId}`, 10).toString(16)}` }],
      })
    } catch (switchError) {
      console.error(switchError)
      // This error code indicates that the chain has not been added to MetaMask.
      const _switchError = switchError as { code: number }
      if (_switchError.code === 4902) {
        try {
          if (network !== 'eth') {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${parseInt(`${chainId}`, 10).toString(16)}`,
                  chainName: networkConfig.description,
                  nativeCurrency: {
                    name: symbol,
                    symbol: symbol,
                    decimals: networkConfig.native_token.decimals,
                  },
                  rpcUrls: [networkConfig.rpc_url || ''],
                  blockExplorerUrls: [networkConfig.block_explorer.url],
                },
              ],
            })
          }
        } catch (addError) {
          console.error(addError)
        }
      }
    }
  } else {
    console.error(
      `Can't setup the ${network.toUpperCase()} network on metamask because window.ethereum is undefined`
    )
    return
  }
}

export default switchNetwork
